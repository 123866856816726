import logo from "./logo.webp"

const  clientData = {
   client_entity: 3,
   attorney_firm: 'dajtaxlaw.com',
    attorney_name: 'Philip Anthony',
    attorney_number: '(601) 944-4290',
    attorney_email: 'djeffords@dajtaxlaw.com',
    client_video_source: ' https://erc-videos.s3.amazonaws.com/ertc_explainer_-_daj+(720p)(REPLACEMENT).mp4',
    mainColor: '#3C3C3C',
    secondaryColor: '#5E5E5E',
    siteLink: 'http://dajtaxlaw.com',
    logo
}

export default clientData